import Logo from './LOGO-02.png';
// import Logo_login from './logoHome.png';

export default {
  logo: Logo,
  logo_width: '150px',
  name: 'Pacto Emprendedor',
  catalogTalents: true,
  ods_profile: false,
  withState: true,
  map_hide: true,
};
